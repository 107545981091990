/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'react-bootstrap';
import { KTIcon } from '../../../_metronic/helpers';
import CurrencyFormat from 'react-currency-format';
import './Deal.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showToastMessageError } from '../../modules/Toast-Message/TosatMessage';
import { AddCommits } from './AddCommits';
import { AddTracking } from './AddTracking';
import { Send } from 'react-ionicons'



const modalsRoot = document.getElementById('root-modals') || document.body;

const Details = ({ show2, handleClose2, propsData2, showCreateAppModalCommit, setShowCreateAppModalCommit, showCreateAppModalTracking, setShowCreateAppModalTracking, setChangedColor }) => {
    const [dealDetail, setDealDeatils] = useState(propsData2?.default_color);
    const [selectedID, setSelectedID] = useState(propsData2?.default_color?.attribute_code);
    const totalPrice = (parseFloat(dealDetail.price) + parseFloat(dealDetail.commission)).toFixed(2);

    const userDetails = localStorage.getItem('kt-auth-react-v');
    const myUser = JSON.parse(userDetails);

    const itemPrice = (val) => {

        var userBadge = myUser.badge;

        if(userBadge != 1){
          if(val.badge_pricings.length > 0){
            var badgePricing = val.badge_pricings.filter((item)=> {
              return item.badge_type ==  userBadge
            });
            const badgePrices = badgePricing[0];
  
            if(badgePrices.price != 0 || badgePrices.commission != 0){
              var badgePrice = badgePrices.price;
              var badgeCommission = badgePrices.commission;
            }
            else{
              var badgePrice = val.price;
              var badgeCommission = val.commission;
            }
  
          }else{
            var badgePrice = val.price;
            var badgeCommission = val.commission;
          } 
        }else{
          var badgePrice = val.price;
          var badgeCommission = val.commission;
        }


        var data = {};
        if (propsData2?.commission_status == 1) {
            const commission = parseFloat(badgeCommission);
            data = {
                orderPrice: parseFloat(val.price) + commission,
                actualPrice: parseFloat(val.price),
                badgePrice: parseFloat(badgePrice),
                badgeCommission: parseFloat(badgeCommission),
                commission: commission,
                total: parseFloat(val.price) + commission,
                badgeTotal: parseFloat(badgePrice) + badgeCommission,
            };
        } else if (propsData2?.retail == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                badgePrice: parseFloat(badgePrice),
                badgeCommission: 0,
                commission: 0,
                total: parseFloat(val.price),
                badgeTotal: parseFloat(badgePrice),
            };
        } else if (propsData2?.below_cost == 1) {
            data = {
                orderPrice: parseFloat(val.price),
                actualPrice: parseFloat(val.price),
                badgePrice: parseFloat(badgePrice),
                badgeCommission: 0,
                commission: 0,
                total: parseFloat(val.price),
                badgeTotal: parseFloat(badgePrice),
            };
        };

          return data;
    };
    

    const formatDate = (myDate) =>{
      const date = new Date(myDate);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
    
      return `${month}-${day}-${year}`;
    }

    useEffect(() => {
      console.log('Commit Modal:', showCreateAppModalCommit);
      console.log('Tracking Modal:', showCreateAppModalTracking);
        if (showCreateAppModalCommit) {
            handleClose2();
        }
        if (showCreateAppModalTracking) {
            handleClose2();
            console.log('Tracking Modal Closed by useEffect')
        }
    }, [showCreateAppModalCommit,showCreateAppModalTracking]);

    useEffect(() => {
        setChangedColor(dealDetail);
    }, [dealDetail]);

    //     <AddCommits
    //     show={showCreateAppModalCommit}
    //     propsData={propsData2}
    //     // imgPath={toAbsoluteUrl('/media/images/1.png')}
    //     handleClose={() => {
    //         setShowCreateAppModalCommit(false);
    //     }}
    //     changedColor={dealDetail.attribute_code}
    //     changedColor2={dealDetail.color}
    //     changePrice={dealDetail.price}
    // />

    const commitFun = () => {
      showToastMessageError('Commitment Full')
    }

    // const formatDate = (myDate) =>{
    //   const formattedDate = myDate.slice(0,10);
    //   return formattedDate
    // }

    return createPortal(
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show2}
        onHide={handleClose2}
        backdrop={true}
      >
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          style={{marginLeft: 'auto'}}
          onClick={handleClose2}
        >
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        <ToastContainer />
        <div>
          <div className='card mt-5'>
            <div className='ribbon ribbon-start ribbon-clip' style={{top: '10px'}}>
              {propsData2.status === 1 ? (
                <div className='ribbon-label'>
                  {propsData2.deal_stock == 'true' ? 'In Stock' : 'Commitment Full'}
                  {propsData2.deal_stock == 'true' ? (
                    <span className='ribbon-inner bg-info'></span>
                  ) : (
                    <span className='ribbon-inner bg-danger'></span>
                  )}
                </div>
              ) : (
                <div className='ribbon-label'>
                  Expired
                  <span className='ribbon-inner bg-warning'></span>
                </div>
              )}
            </div>
            <div className='row col-12'>
              <div className='card-body col-4 d-flex flex-column justify-content-between'>
                <div className={`card-rounded text-center`}>
                  <img
                    src={dealDetail.image ? dealDetail.image : propsData2?.default_color?.image}
                    alt=''
                    style={{width: '200px', height: 'auto'}}
                  />
                </div>

                <div className='row border border-gray-300 border-dashed rounded py-3 px-3'>
                  <h5><span className='fw-semibold text-muted'>Committed Qty :</span> {propsData2?.user_commited_qty}</h5>
                  <hr />
                  <div className="col-6">
                    <div className=''>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-50px'>{propsData2?.user_pending_commit}</span>
                      </div>
                      <div className='fw-semibold text-muted'>Pending</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className=''>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-50px'>{propsData2?.user_tracking_qty}</span>
                      </div>
                      <div className='fw-semibold text-muted'>On Tracking</div>
                    </div>
                  </div>
                </div>

              </div>

              <div className='col-8'>
                <div className='mb-5'>
                  <div className='d-flex flex-wrap'>
                    {dealDetail?.stock ? (
                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-75px'>{Math.max(dealDetail?.stock - dealDetail?.commited_qty, 0)}</span>
                      </div>
                      <div className='fw-semibold text-muted'>Available Qty</div>
                    </div>
                    ) : (
                      <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-75px'>0</span>
                      </div>
                      <div className='fw-semibold text-muted'>Available Qty</div>
                    </div>
                    )}

                    <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3 mx-3'>
                      <div className='fs-4 fw-bold text-gray-700'>
                        <span className='w-75px'>{propsData2.user_limit}</span>
                      </div>
                      <div className='fw-semibold text-muted'>Maximum Order Limit</div>
                    </div>


                  </div>
                  <div className='mt-2'>
                    <div className='card-rounded p-2 z-index-1 bg-body'>
                      <div
                        className='shadow-xs align-items-center'
                        style={{marginLeft: '-15px', marginTop: '-15px', padding: '10px'}}
                      >
                        <div className='d-flex align-items-center flex-wrap w-100'>
                          <div className='pe-3 flex-grow-1'>
                          <h5 className="mt-3">Deal ID : <u>{propsData2?.deal_no}</u></h5>
                            <h4><a href='#' className='fs-4 fw-bold text-gray-700 text-hover-dark mt-3'>
                              <strong>
                                {propsData2?.name}-{dealDetail.color}
                              </strong>
                            </a></h4>
                            
                            <div className='d-flex align-items-center mt-5'>
                              <div className='me-5'>
                                <div className='fs-5 symbol-label'>Deal Expires:</div>
                                <div className='fs-5 symbol-label'>Price:</div>
                                {/* {itemPrice(dealDetail).commission !== 0 && (
                                  <div className='fs-5 symbol-label'>Commission:</div>
                                )} */}
                                  <div className='fs-5 symbol-label'>Commission:</div>
                                <div className='fs-5 symbol-label'>Model:</div>
                                <div className='fs-5 fw-bold symbol-label'>Total:</div>
                              </div>
                              <div className=''>
                                <div className='fs-5 text-gray-800 pe-1'>{formatDate(propsData2?.expires)}</div>
                                <div className='fs-5 text-gray-800 pe-1'>
                                  <CurrencyFormat
                                    decimalScale={2}
                                    value={itemPrice(dealDetail).badgePrice.toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                  />
                                </div>
                                {console.log('currentDetail',itemPrice(dealDetail))}
                                {/* {itemPrice(dealDetail).commission !== 0 && (
                                  <div className='fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat
                                      decimalScale={2}
                                      value={itemPrice(dealDetail).commission}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                    />
                                  </div>
                                )} */}
                                  <div className='fs-5 text-gray-800 pe-1'>
                                    <CurrencyFormat
                                      decimalScale={2}
                                      value={itemPrice(dealDetail).badgeCommission.toFixed(2)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                    />
                                  </div>

                                <div className='fs-5 text-gray-800 pe-1'>{dealDetail.model}</div>
                                <div className='fw-bold fs-5 text-gray-800 pe-1'>
                                  <CurrencyFormat
                                    decimalScale={2}
                                    value={itemPrice(dealDetail).badgeTotal.toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <ul className='deal_colors'>
                                <div className='mt-3 fs-5 mb-2'>Color Available:</div>
                                {propsData2?.colors.map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      onClick={() => {
                                        setDealDeatils(item)
                                        setSelectedID(item.attribute_code)
                                      }}
                                    >
                                      <div
                                        className={
                                          item.attribute_code == selectedID
                                            ? 'color-item deal_colors_selected'
                                            : 'color-item deal_colors_select'
                                        }
                                        style={{borderColor: `${item.color_code}`}}
                                      >
                                        <div
                                          className='colorBox'
                                          style={{background: `${item.color_code}`,border:'2px solid black'}}
                                        ></div>
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='mt-5'>
                          <div className='d-flex align-items-center col-12'>
                            <div className='col-12 d-flex'>
                              {/* <div className='mt-3 fs-5 '></div> */}
                              <div  className='mt-3 fs-5'>Shipping Location : {propsData2?.shipping_address} - 
                                <span>{propsData2?.shipping_zipcode}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-none'>
                  <div className='fw-bold fs-4 text-primary'>Items</div>
                  <div
                    style={{marginTop: '20px', padding: '10px'}}
                    className='shadow-xs row col-12'
                  >
                    <div className='col-2'>
                      <div className={`border-right card-rounded text-center`}>
                        <img
                          src={propsData2?.image}
                          alt=''
                          style={{width: '50px', height: '50px', objectFit: 'contain'}}
                        />
                      </div>
                    </div>
                    <div className='border-left col-8'>
                      <div className='fw-bold fs-7'>{propsData2?.name}</div>
                      <div className='row col-12 mt-5'>
                        <div className='col-6'>
                          <div>
                            <strong>UPC &nbsp;</strong> {propsData2?.upc}
                          </div>
                          <div>
                            <strong>Model &nbsp;</strong> {propsData2?.model}
                          </div>
                          <div>
                            <strong>Price &nbsp;</strong> ${propsData2?.price}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div>
                            <strong>User Limit &nbsp;</strong> {propsData2?.user_limit}
                          </div>
                          <div>
                            <strong>Commission &nbsp;</strong> ${propsData2?.commission}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-2'></div>
                  </div>
                </div>
                <p className='fs-5'><i class="fa-solid fa-bullhorn me-2 text-dark fa-3x"></i><b>Note</b> :- {propsData2?.note ? propsData2?.note : 'There is nothing'}</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='d-flex justify-content-between'>
          <div className='' style={{paddingRight: '10px', paddingBottom: '10px'}}>
            <button onClick={handleClose2} type='button' className='btn btn-danger  mx-5'>
              Close
            </button>
          </div>
          <div className=' ' style={{paddingRight: '10px', paddingBottom: '10px'}}>
            {propsData2.status != 2 && propsData2.freezed_status == 0 && (
              Math.max(dealDetail?.stock - dealDetail?.commited_qty, 0) != 0 ? (
              <>
              <button
                onClick={() => {
                   setShowCreateAppModalTracking(true)
                }}
                type='button'
                className='btn btn-success fw-bold me-5'
              >
                Instant Track&nbsp;&nbsp;<Send color={'white'} height="15px" width="15px" />
              </button>
              <button
                onClick={() => {
                   setShowCreateAppModalCommit(true)
                }}
                type='button'
                className='btn btn-warning fw-bold'
              >
                Commit&nbsp;&nbsp;<Send color={'white'} height="15px" width="15px" />
              </button>
              </>
              ) : (
              <>
              <button
                onClick={() => {
                  commitFun()
                }}
                type='button'
                className='btn btn-secondary fw-bold me-5'
              >
                Instant Track&nbsp;&nbsp;<Send color={'white'}  height="15px" width="15px" />
              </button>
              <button
                onClick={() => {
                  commitFun()
                }}
                type='button'
                className='btn btn-secondary fw-bold'
              >
                Commit&nbsp;&nbsp;<Send color={'white'}  height="15px" width="15px" />
              </button>
              </>
              )
            )}
          </div>
        </div>
      </Modal>,
      modalsRoot
    )
}

export { Details };
